import styled from 'styled-components'

export const ContainerHamburgerMenu = styled.div`
  @media only screen and (min-width: 699px) {
    display: none;
  }
`

export const BtnHamburgerMenu = styled.div`
  cursor: pointer;

  font-size: ${({ theme }) => theme.font.sizes.xlarge};
  color: ${({ theme }) => theme.colors.black};

  padding: 1rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray200};
`

export const ContentNavHamburgerMenu = styled.nav`
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.4rem;

  margin-left: 10px;

  > ul {
    flex-direction: column;
    display: flex;
    gap: 2.4rem;

    a {
      font-family: ${({ theme }) => theme.font.secondary};
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.black};

      &:hover {
        color: ${({ theme }) => theme.colors.gray700};
      }
    }
  }
`
