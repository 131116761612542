import { useEffect, useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { UserOutlined } from '@ant-design/icons'

import { HamburgerMenu } from './HamburgerMenu'

import { ButtonUI } from '@/components/UI/ButtonUI'

import { getInfoUser, isUserLoggedIn } from '@/services/UserService'

import * as S from './styles'

import imgLogoSCidades from '../../../../public/svg/logos/scidades/logo_horizontal_scidades.svg'
import imgLogo from '../../../../public/svg/logos/logo-horizontal.svg'

export function PublicHeader() {
  const [userInitial, setUserInitial] = useState<string | undefined>('')

  useEffect(() => {
    if (isUserLoggedIn()) {
      const userInfo = getInfoUser()
      setUserInitial(userInfo?.name)
    }
  }, [])

  return (
    <S.ContainerPublicHeader>
      <S.ContentPublicHeader>
        <S.WrappePublicHeader>
          <div className="public-header-left">
            <Link href={'/'}>
              <Image
                src={imgLogo}
                alt="Logo do projeto moradia ceara"
                sizes="100vw"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Link>
            <Link
              href="https://www.cidades.ce.gov.br/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={imgLogoSCidades}
                alt="Logotipo do Governo do Estado do Ceará"
                sizes="100vw"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Link>
          </div>

          <nav className="public-header-mid">
            <ul>
              <li>
                <Link href="/#inicio">INÍCIO</Link>
              </li>
              <li>
                <Link href="/#o-programa">O PROGRAMA</Link>
              </li>
              <li>
                <Link href="/#como-participar">COMO PARTICIPAR</Link>
              </li>
              <li>
                <Link href="/#duvidas">DÚVIDAS</Link>
              </li>
              <li>
                {!userInitial ? (
                  <a href={`${process.env.NEXT_PUBLIC_LOGIN_WEP_APP_WITH_KEY}`}>
                    <ButtonUI
                      buttonType="secondary"
                      size="medium"
                      iconRN={
                        <UserOutlined
                          style={{
                            fontSize: '1.6rem'
                          }}
                        />
                      }
                    >
                      ENTRAR
                    </ButtonUI>
                  </a>
                ) : (
                  <Link href="/empresa/perfil">
                    <S.UserInfo>{userInitial[0]}</S.UserInfo>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <HamburgerMenu />
        </S.WrappePublicHeader>
      </S.ContentPublicHeader>
    </S.ContainerPublicHeader>
  )
}
