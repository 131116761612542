import { useState } from 'react'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import Link from 'next/link'

import { ButtonUI } from '@/components/UI/ButtonUI'

import * as S from './styles'

export function HamburgerMenu() {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <S.ContainerHamburgerMenu>
      {!isOpenMenu ? (
        <S.BtnHamburgerMenu onClick={() => setIsOpenMenu((old) => !old)}>
          <MenuOutlined />
        </S.BtnHamburgerMenu>
      ) : (
        <S.ContentNavHamburgerMenu>
          <ul>
            <li onClick={() => setIsOpenMenu(false)}>
              <Link href="/#inicio">INÍCIO</Link>
            </li>
            <li onClick={() => setIsOpenMenu(false)}>
              <Link href="/#o-programa">O PROGRAMA</Link>
            </li>
            <li onClick={() => setIsOpenMenu(false)}>
              <Link href="/#como-participar">COMO PARTICIPAR</Link>
            </li>
            <li onClick={() => setIsOpenMenu(false)}>
              <Link href="/#duvidas">DÚVIDAS</Link>
            </li>
            <Link href="/entrar">
              <ButtonUI
                buttonType="secondary"
                iconRN={
                  <UserOutlined
                    style={{
                      fontSize: '1.6rem'
                    }}
                  />
                }
              >
                ENTRAR
              </ButtonUI>
            </Link>
          </ul>
        </S.ContentNavHamburgerMenu>
      )}
    </S.ContainerHamburgerMenu>
  )
}
