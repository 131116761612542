import styled from 'styled-components'

export const ContainerPublicHeader = styled.div`
  width: 100%;

  z-index: 10;
`

export const ContentPublicHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 52px;
  border-radius: 52px;

  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.large};
  color: ${({ theme }) => theme.colors.white};

  background-color: ${({ theme }) => theme.colors.brandOrange500};

  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
`

export const WrappePublicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  min-height: 100px;
  width: 100%;
  max-width: 1140px;

  border-radius: 48px 48px 0px 0px;
  padding: 3.5rem 48px;

  background-color: ${({ theme }) => theme.colors.white};

  > div.public-header-left {
    display: flex;
    gap: 0.8rem;

    position: relative;

    max-width: 320px;
    min-width: 75px;
    max-height: 50px;
    width: 100%;
    height: 100%;
  }

  > nav.public-header-mid {
    > ul {
      display: flex;
      align-items: center;
      gap: 2.4rem;

      a {
        font-family: ${({ theme }) => theme.font.secondary};
        font-weight: ${({ theme }) => theme.font.bold};
        font-size: ${({ theme }) => theme.font.sizes.medium};
        color: ${({ theme }) => theme.colors.black};

        &:hover {
          color: ${({ theme }) => theme.colors.brandOrange};
        }
      }
    }
  }

  @media only screen and (max-width: 699px) {
    > nav.public-header-mid,
    .btn-header {
      display: none;
    }
  }
`
